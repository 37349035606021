import React, { Children, forwardRef, FunctionComponent, ReactNode, useEffect, useImperativeHandle, useRef, useState } from "react";
import styles from "./gameRules.module.scss";
import stylesApp from '../../globalStyles.module.scss';
import { inherits } from "util";
import { Settings } from "../../helpers/Settings";
import { ArrowLeft, ArrowLeftLarge, ArrowRight, ArrowRightLarge, CloseModal, Coin, Cost, Gain, Household, HouseholdColored, LocalStockColored, LocalStorage, MiniPickup, Pickup, RecyclingCenter, RecyclingCenterColored, Transfer } from "../../components/icons/icons";
import { ElementFlags } from "typescript";
import Card, { CardCommunicators } from "../../components/Card/Card";
import { shrinkFloatingPoint } from "../../handlers/correctors";

interface GameRulesProps {
    title?: string[];
    fontFamily?: string;
    fontSize?: string;
    onClick?: (value: any) => void;
    onChange?: (value: any) => void;
    onClose?: () => void
    children?: React.ReactNode;
}

export interface GameRulesCommunicators {
    onUnload: () => void;
}

const GameRules: FunctionComponent<GameRulesProps> = (props) => {

    const [title, setTitle] = useState<string[]>(props.title!);
    
    useEffect(() => {

    }, [])

    return (
        <div className={styles.container}>
            <div className={stylesApp.separator}></div>
            <div className={styles.header}>
                <div className={styles.title}>
                    <div className={styles.titleTop}>
                        {title![0]}
                    </div>
                    <div className={styles.titleBottom}>
                        {title![1]}
                    </div>
                </div>
                <div className={styles.close}>
                    <CloseModal onClick={() => {
                        if (props.onClose!)
                            props.onClose();
                    }} />
                </div>
            </div>
                <div className={styles.separatorDouble}></div>
            <div className={styles.body}>
                <div className={stylesApp.splitterDouble}></div>
                <div className={stylesApp.splitterDouble}></div>
                <div className={stylesApp.splitterDouble}></div>
                <img src={require('../../assets/images/gameRules.svg').default}></img>
                <div className={stylesApp.splitterDouble}></div>
                <div className={stylesApp.splitterDouble}></div>
                <div className={stylesApp.splitterDouble}></div>
            </div>
                <div className={styles.separatorDouble}></div>
        </div>
    );
}

export default GameRules;