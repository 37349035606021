import React, { Children, forwardRef, FunctionComponent, ReactElement, ReactNode, useContext, useEffect, useImperativeHandle, useRef, useState } from "react";
import styles from "./card.module.scss";
import stylesApp from '../../globalStyles.module.scss';
import { Camion, CardInfo, Coin, Cost, Gain, MiniPickup, Pickup, Truck } from "../icons/icons";
import { PopupsContext } from "../../handlers/PopupsContext";

interface CardProps {
    type: 'MINIPICKUP' | 'PICKUP' | 'CAMION' | 'TRUCK';
    cost?: number;
    gainPerUnit: number;
    payloadType: 'TRASHCAN' | 'PALLET';
    description: string;
    descriptionInfo?: string|undefined;
}

export interface CardCommunicators{
    refreshValues: () => void;
}


//const Card: FunctionComponent<CardProps> = (props) => {
const Card = forwardRef<CardCommunicators, CardProps>((props, ref) => {

    //const componentRef = useRef();
    const { showModal, showInfoPopup, showAlertPopup, showBusy, hideBusy } = useContext(PopupsContext);
    const [title, setTitle] = useState<string>();
    const [icon, setIcon] = useState<ReactNode>();

    useImperativeHandle(ref, () => ({
        refreshValues() {
            // setAnimation(styles.unloadAnimation);
            // setTimeout(() => {
            //     if(props.onClose!)
            //         props.onClose();
            // }, Settings.inApp.modalDialogUnloadTime);
        }
    }));

    useEffect(() => {
        switch (props.type) {
            case 'MINIPICKUP':
                setTitle('mini pickup');
                setIcon(<MiniPickup />);
                break;
            case 'PICKUP':
                setTitle('pickup');
                setIcon(<Pickup />);
                break;
            case 'CAMION':
                setTitle('camion');
                setIcon(<Camion />);
                break;
            case 'TRUCK':
                setTitle('truck');
                setIcon(<Truck />);
                break;
        }
    }, [])

    return (
        <div className={styles.card}>
            <div className={styles.agentFrame}>
                <div className={styles.top}>
                    <div className={styles.splitter}></div>

                    <div className={styles.iconSection}>
                        <div className={styles.iconTitle}>
                            {title}
                        </div>
                        <div className={styles.icon}>
                            {icon}
                        </div>
                    </div>
                    
                    <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                        <div className={styles.topFill}></div>
                        <div className={stylesApp.verticalConnector} style={{ height: '70px' }}>
                        </div>
                    </div>

                    <div className={styles.priceSection}>
                        <div className={styles.topFill}></div>
                        <div className={styles.pricesArea}>
                            <div className={styles.prices}>
                                <div className={styles.price}>
                                    <div className={styles.direction}>
                                        <Cost />
                                    </div>
                                    <div className={styles.value}>
                                        <div className={styles.amount}>
                                            {props.cost}
                                        </div>
                                        <div className={styles.coin}>
                                            <Coin />
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.divider} ></div>
                                <div className={styles.price}>
                                    <div className={styles.direction}>
                                        <Gain />
                                    </div>
                                    <div className={styles.value}>
                                        <div className={styles.amount}>
                                            {props.gainPerUnit}
                                        </div>
                                        <div className={styles.coin}>
                                            <Coin />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.splitter}></div>
                </div>
                <div className={styles.description}>
                    {props.description}
                    &nbsp;
                    {props.descriptionInfo! &&
                        <CardInfo onClick={() => {
                            showModal('game knowledge', props.descriptionInfo!, undefined, '', () => {}, () => {});
                        }}/>
                    }
                </div>
            </div>
        </div>
    );
});

export default Card;