import React, { useContext, useEffect, useReducer, useRef, useState } from 'react';
import styles from './App.module.scss';
import stylesApp from './globalStyles.module.scss';
import styleBubbles from './bubbles.module.scss';
import { initiateUser, UserData } from './restAPI/userGate';
import { getStack, setStack } from './restAPI/stackGate';
import { Bottle, BottleStatistics, Camion, CloseModal, Coin, Folder, Household, LocalStock, Menu, MenuGameRules, MenuHome, MenuLedger, MenuLocalStock, MenuTapped, MiniPickup, Oxygen, TrashCan, TrashCanLight, Truck, Warehouse } from './components/icons/icons';
import { PopupsContext } from './handlers/PopupsContext';
import GlobalWrapper from './handlers/GlobalWrapper';
import PopupsWrapper from './handlers/PopupsWrapper';
import Modal, { ModalCommunicators } from './components/Modal/Modal';
import Operations from './pages/Operations/Operations';
import { Settings } from './helpers/Settings';
import { badgeLevelColor, stringifyNumber } from './handlers/ui';
import GameRules from './pages/GameRules/GameRules';
import Journal from './pages/Journal/Journal';
import { GlobalContext } from './handlers/GlobalContext';
import { info } from 'console';
import { getBalance } from './restAPI/walletGate';
//type ChildElement = React.ReactElement<{ datKey: string; onAnimationEnd: (event: React.AnimationEvent<SVGSVGElement>) => void }>;


declare global {
  interface Window {
    Telegram: {
      WebApp: {
        initData: string;
        initDataUnsafe: {
          user: {
            id: number;
            first_name: string;
            last_name: string;
            username: string;
            language_code: string;
          };
        };
        expand: () => void;
        sendData: (data: string) => void;
      };
    };
  }
}

function App() {
  
  const [animatedChildren, setAnimatedChildren] = useState<React.ReactNode[]>([]);
  const [animatedTrashCan, setAnimatedTrashCan] = useState<React.ReactNode[]>([]);
  const globalTime = useRef<NodeJS.Timer>();
  const { setUser, user } = useContext(GlobalContext);
  const { showModal, showInfoPopup, showAlertPopup, showBusy, hideBusy } = useContext(PopupsContext);
  
  const modalsRef = useRef<ModalCommunicators>(null);
  
  const [oxygenLevel, setOxygenLevel] = useState<number>(66);
  const [pollutionLevel, setPollutionLevel] = useState<number>(85);

  const [collectedWorth, setCollectedWorth] = useState<number>(0);
  const [menuTapped, setMenuTapped] = useState<boolean>(false);
  const [bottleClicked, setBottleClicked] = useState<boolean>(false);
  const [transferLocalStock, setTransferLocalStock] = useState<boolean>(false);
  const [transferRecyclingCenter, setTransferRecyclingCenter] = useState<boolean>(false);
  const [gameRules, setGameRules] = useState<boolean>(false);
  const [achievements, setAchievements] = useState<boolean>(false);

  //#region Telegram userData
  const [username, setUsername] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  //const [userId, setUserId] = useState('');
  const [currentUser, setCurrentUser] = useState<UserData>();
  const [initResult, setInitResult] = useState('no response ...');
  //#endregion

  //#region user state data
  const [collectedBottles, setCollectedBottles] = useState<number>(0);
  const [palletsInRent, setPalletsInRent] = useState(7);
  const [fullTrashCansInhouse, setFullTrashCansInhouse] = useState<number>(0);
  const [fullTrashCansInStock, setFullTrashCansInStock] = useState<number>(0);
  const [inStockPallets, setInStockPallets] = useState<number>(0);

  const [consoleLog, setConsoleLog] = useState<string>('');
  //#endregion user state data

  useEffect(() => {
    (async () => {
      //#region ToDO: remove from here 
      if (process.env.NODE_ENV !== 'production') {
        setCurrentUser({
          telegramId: '1456278874',
          name: 'Pi_dev',
          surname: 'A_dev',
          username: 'imig_prog_dev'
        });
      }
      //#endregion ToDO: remove to here 

      //#region Linking Telegram account
      // Check if the Telegram WebApp object exists (it will be available only when opened in the Telegram app)
      if (window.Telegram && window.Telegram.WebApp) {
        const tg = window.Telegram.WebApp;
        // Extract user data from the WebApp initDataUnsafe object
        const userTelegram = tg.initDataUnsafe?.user;
        if (userTelegram) {
          setCurrentUser({
            telegramId: userTelegram.id.toString(),
            name: userTelegram.first_name,
            surname: userTelegram.last_name,
            username: userTelegram.username
          })
        }

        // Expand the WebApp to fill the entire screen
        tg.expand();
      }
      //#endregion Linking Telegram account
    })();
    
    globalTime.current = setInterval(() => {
      const difference = (Math.random() * 10);
      setPollutionLevel(Math.ceil(85 - difference));
      setOxygenLevel(Math.ceil(66 - (((pollutionLevel < (85 - difference)) ? -1 : 1) * (Math.random() * 10))));
    }, 7000);
    return () => {
      clearInterval(globalTime.current);
    }
  }, []);

  const contextMenu = (event: any) => {
      event.preventDefault();
  }

  useEffect(() => {
    loadDetails();
    (async() => {
      // const result = await getBalance(user!);
      // if(result!)
      //   console.log('Wallet Balance: ' + result.worth);
    })();
  }, [currentUser]);

  useEffect(() => {
    (async () => {
    })();
  }, [menuTapped]);

  useEffect(() => {
    //setcollectedWorthDisplayable(toWorthString(weight * 250 / 1000));    
    //}, [collectedPlastics, currentUser]);//username, firstName, lastName, userId]);
    // if (fullTrashCans >= Settings.inApp.inHouseCapacity){
    //   showAlertPopup('your household capacity is full, transfer them to either Local Stock or Recycling Center, before collecting more!', 'info', 3000);
    // }
  }, [collectedBottles, inStockPallets, fullTrashCansInStock, username, firstName, lastName, initResult, pollutionLevel]);

  const loadDetails = async () => {
    if (currentUser!) {
      showBusy('loading', 'loading ...');
      const initiationResult = await initiateUser(currentUser!);

      if (initiationResult!) {
        if (initiationResult.userId!) {
          setUser(initiationResult.userId);
          const resultInhouse = await getStack(initiationResult.userId!, 'INHOUSE');
          //console.log(resultInhouse);
          if (resultInhouse!) {
            setFullTrashCansInhouse(resultInhouse.canCount);
          }

          const resultInStock = await getStack(initiationResult.userId!, 'INSTOCK');
          if (resultInStock!) {
            setFullTrashCansInStock(resultInStock.canCount);
            const pallets = resultInStock.canCount / Settings.inApp.palletCapacity;
            setInStockPallets(pallets);
          }
        }

        const resultBalance = await getBalance(initiationResult.userId!);
        if(resultBalance!)
          setCollectedWorth(resultBalance.worth ?? 0);
      }
      hideBusy();
    }
  };

  const collectBottle = () => {
    if (menuTapped)
      setMenuTapped(false);
    if (fullTrashCansInhouse! < Settings.inApp.inHouseCapacity) {
      if (collectedBottles < Settings.inApp.trashCanCapacity - 1) {
        setCollectedBottles(collectedBottles + 1);
      }
      else {
        const elementKey = Date.now().toString();
        const newChild = <div key={elementKey} id={elementKey} className={styles.trashCanEffect} onAnimationEnd={handleTrashCanAnimationEnd}><TrashCanLight/></div>;
        setAnimatedTrashCan((prevChildren:any) => [...prevChildren, newChild]);

        setCollectedBottles(0);
        setFullTrashCansInhouse(fullTrashCansInhouse! + 1);
        showBusy('busy', 'transferring ...');
        setStack(user!, 1, 'INHOUSE', 'ONSHORE');
        hideBusy();
      }
    }
    else
      showAlertPopup('', 'info', 5000, 
        <div>your household capacity is full, transfer them to either <b>Local Stock</b> or <b>Recycling Center</b>, before collecting more!</div>);
  }

  const handleAnimationEnd = (event: React.AnimationEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    //console.log(animatedChildren.length);
    setAnimatedChildren((prevChildren:any) => prevChildren.filter((child:any) => child.key !== target.id));
  };

  const handleTrashCanAnimationEnd = (event: React.AnimationEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    //console.log(animatedChildren.length);
    setAnimatedChildren((prevChildren:any) => prevChildren.filter((child:any) => child.key !== target.id));
  };

  return (
    <div className={styles.container} onKeyDownCapture={(event) => {
      event.stopPropagation();      
    }}>
      {transferLocalStock &&
        <Modal zIndex='1000' glassEffect='CLEARGLASS' ref={modalsRef} onClose={() => { setTransferLocalStock(false) }}>
          <Operations userId={user} title={['transfer to', 'local stock']} origin='HOUSE'
            //trashCans={fullTrashCansInhouse!}
            onChange={(value: number, type: 'INHOUSE' | 'LOCALSTOCK') => {
              if (type === 'INHOUSE')
                setFullTrashCansInStock(0);//fullTrashCansInhouse! - value);
            }}
            onClose={() => {
              if (modalsRef.current) {
                modalsRef.current.onUnload();
                setMenuTapped(false);
                loadDetails();
              }
            }}></Operations>
        </Modal>
      }
      {transferRecyclingCenter &&
        <Modal zIndex='1000' glassEffect='CLEARGLASS' ref={modalsRef} onClose={() => { setTransferRecyclingCenter(false) }}>
          <Operations userId={user} title={['transfer for', 'recycling']} origin='LOCALSTOCK'
            //trashCans={fullTrashCansInStock!}
            onChange={(value: number, type: 'INHOUSE' | 'LOCALSTOCK') => {
              if (type === 'LOCALSTOCK')
                setInStockPallets(0);//inStockPallets! - value);
            }}
            onClose={() => {
              if (modalsRef.current) {
                modalsRef.current.onUnload();
                setMenuTapped(false);
                loadDetails();
              }
            }}></Operations>
        </Modal>
      }
      {achievements &&
        <Modal zIndex='1000' glassEffect='CLEARGLASS' ref={modalsRef} onClose={() => { setAchievements(false) }}>
          <Journal userId={user!} title={['journal of', 'achievements']} onClose={() => {
            if (modalsRef.current) {
              modalsRef.current.onUnload();
              setMenuTapped(false);
            }
          }}></Journal>
        </Modal>
      }
      {gameRules &&
        <Modal zIndex='1000' glassEffect='CLEARGLASS' ref={modalsRef} onClose={() => { setGameRules(false) }}>
          <GameRules title={['game rules', 'how it works?']} onClose={() => {
            if (modalsRef.current) {
              modalsRef.current.onUnload();
              setMenuTapped(false);
            }
          }}></GameRules>
        </Modal>
      }
      <div className={menuTapped ? (`${styles.menu} ${styles.menuHover}`) : styles.menu}>
        {menuTapped ? <MenuTapped className={styles.menuIcon} onClick={() => { setMenuTapped(!menuTapped); }} /> : <Menu className={styles.menuIcon} onClick={() => { setMenuTapped(!menuTapped); }} />}
        {menuTapped &&
          <>
            <div className={`${styles.menuItem} ${styles.warehouse}`}>
              <MenuHome className={styles.itemIcon}
                onClick={() => {
                  setTransferLocalStock(true);
                }} />
            </div>
            <div className={`${styles.menuItem} ${styles.miniPickup}`}>
              <MenuLocalStock className={styles.itemIcon}
                onClick={() => {
                  setTransferRecyclingCenter(true);
                }} />
            </div>
            <div className={`${styles.menuItem} ${styles.camion}`}>
              <MenuLedger className={styles.itemIcon}
                onClick={() => {
                  setAchievements(true);
                }} />
            </div>
            <div className={`${styles.menuItem} ${styles.truck}`}>
              <MenuGameRules className={styles.itemIcon}
                onClick={() => {
                  setGameRules(true);
                }} />
            </div>
          </>
        }
      </div>
      <div className={styles.menuClickBackground}></div>


      <div className={styles.globalLight}>
      </div>

      <div className={styles.globalFloor}>
      </div>

      {/* #region bottles */}
      <div className={styles.effectContainer}>
        {animatedChildren}
        {animatedTrashCan}
      </div>
      <div className={styles.bottles} style={{ zIndex: '100', bottom: '50%', left: 'calc(50% - 92px)' }}>
        <img src={require('./assets/images/bottles.svg').default} height='100%' style={{ opacity: '0.8' }} 
          onClick={(event) => {
            const elementKey = Date.now().toString();
            const newChild = <div key={elementKey} id={elementKey} className={styles.bottleEffect} onAnimationEnd={handleAnimationEnd}><Bottle/></div>;
            setAnimatedChildren((prevChildren:any) => [...prevChildren, newChild]);
            event.stopPropagation();
            collectBottle();
          }}
          onDoubleClick={(event) => {
            event.preventDefault();
          }}
          onContextMenu={(event) => {
            event.preventDefault();
          }}
          />
      </div>
      {/* #endregion bottles */}

      {/* #region dynamic Algaes */}
      <div className={styles.dynamicAlgae} style={{ zIndex: '2', bottom: '10px', left: '5%' }}>
        <img src={require('./assets/images/algae/Algae.png')} height='100%' style={{ opacity: '0.8' }} />
      </div>

      <div className={styles.dynamicAlgae} style={{ zIndex: '2', bottom: '0px', left: '40%' }}>
        <img src={require('./assets/images/algae/Algae.png')} height='70%' style={{ opacity: '0.8' }} />
      </div>

      <div className={styles.dynamicAlgae} style={{ zIndex: '2', bottom: '5px', right: '5%' }}>
        <img src={require('./assets/images/algae/Algae.png')} height='70%' style={{ opacity: '0.8' }} />
      </div>
      {/* #endregion dynamic Algaes */}

      {/* #region static Algaes */}
      <div className={styles.algae} style={{ zIndex: '2', bottom: '0px', left: '0px' }}>
        <img src={require('./assets/images/algae/Algae0130.png')} height='80%' style={{ opacity: '1' }} />
      </div>

      <div className={styles.algae} style={{ zIndex: '1', bottom: '0px', left: '25%' }}>
        <img src={require('./assets/images/algae/Algae0090.png')} height='100%' style={{ opacity: '1' }} />
      </div>

      <div className={styles.algae} style={{ zIndex: '1', bottom: '0px', right: '25%' }}>
        <img src={require('./assets/images/algae/Algae0000.png')} height='100%' style={{ opacity: '1' }} />
      </div>
      {/* #endregion static Algaes */}

      {/* #region bubbles */}
      {/* //ToDO: replace css for Bubbles and moving Algeas with animated videos */}
      <div className={styles.bubbles} style={{ zIndex: '0', bottom: '10%', left: '0px' }}>
        <img src={require('./assets/images/bubbles.gif')} height='100%' style={{ opacity: '0.7', maxWidth: '95%' }} />
      </div>

      <div className={styles.bubbles} style={{ zIndex: '0', bottom: '35%', left: '25%' }}>
        <img src={require('./assets/images/bubbles.gif')} height='100%' style={{ opacity: '0.7', maxWidth: '95%' }} />
      </div>

      <div className={styles.bubbles} style={{ zIndex: '0', bottom: '20%', left: '70%' }}>
        <img src={require('./assets/images/bubbles.gif')} height='100%' style={{ opacity: '0.7', maxWidth: '95%' }} />
      </div>
      {/* <div className={styleBubbles.oceanFrame}>
        <div className={styleBubbles.oceanContainer}>
          <div className={styleBubbles.bubble}></div>
          <div className={styleBubbles.bubble}></div>
          <div className={styleBubbles.bubble}></div>
          <div className={styleBubbles.bubble}></div>
          <div className={styleBubbles.bubble}></div>
          <div className={styleBubbles.bubble}></div>
          <div className={styleBubbles.bubble}></div>
        </div>
      </div> */}
      {/* #endregion bubbles */}

      {/* #region user worth */}
      <div className={styles.collectionDataContainer}>
        <div className={styles.currentBottles}>
          <div className={styles.text}>
            {collectedBottles}<BottleStatistics height={24} width={24} />
          </div>
        </div>
        <div className={styles.currentWorth}>
          <div className={styles.text}>
            {stringifyNumber(collectedWorth)}
          </div>
        </div>
        <Coin className={styles.coinIcon} />
      </div>
      {/* #endregion user worth */}

      {/* #region environment statistics */}
      <div className={`${styles.guageContainer} ${styles.pollutionContainer}`} onClick={() => {
        showModal('Pollution Level', 'Level of Pollution in oceans, which you help to decrease with collecting bottles!', null, '', () => {}, () => {});
      }}>
        <div className={`${styles.guageCylinder} ${styles.pollutionCylinder}`} style={{ height: `${pollutionLevel}%` }}>
        </div>
        <div className={styles.guageDataContainer}>
          <div className={styles.guageData}>
            {`Pollution ${pollutionLevel}%`}
          </div>
        </div>
        <Bottle className={styles.guageIcon} />
      </div>
      <div className={`${styles.guageContainer} ${styles.o2Container}`} onClick={() => {
        showModal('Oxygen Level', 'you help to increase the level of Oxygen, deep marine algaes produce by collecting plastic bottles in the Ocean!', null, '', () => {}, () => {});
      }}>
        <div className={`${styles.guageCylinder} ${styles.o2Cylinder}`} style={{ height: `${oxygenLevel}%` }}>
        </div>
        <div className={styles.guageDataContainer}>
          <div className={styles.guageData}>
            {`Oxygen ${oxygenLevel}%`}
          </div>
        </div>
        <Oxygen className={styles.guageIcon} />
      </div>
      {/* #endregion environment statistics */}

      <div className={`${styles.locationStats} ${styles.localStock}`} onClick={() => {
        showModal('Local Stock Pallets Space', 'number of full pallets in your rented space at Local Stock! each time with a 3 OCs payment, you Rent a 7 pallets space in Local Stock! it automatically charges on daily basis via your balance! Local Stock will evacuate your Pallts Stock in case you have not enough balance to keep the required space on each day! you need Local Stock spaces to collect more and gain more using high load transferer vehicles like trucks!', null, '', () => {}, () => {});
      }}>
        <LocalStock />
        <div className={styles.capacity}>{palletsInRent}</div>        
        {(fullTrashCansInStock! > 0 && inStockPallets! >= 1) &&
          <div className={`${styles.badge} ${stylesApp.badge}`}
            style={{
              backgroundImage:
                `radial-gradient(circle, ${badgeLevelColor(inStockPallets!, palletsInRent)} 50%, rgba(255,255,255,1) 100%)`
            }}>
            {Math.floor(inStockPallets!) < inStockPallets! ? (Math.floor(inStockPallets!) + '+') : inStockPallets!}
          </div>
        }
      </div>

      <div className={`${styles.locationStats} ${styles.household}`} onClick={() => {
        showModal('Trash Cans', 'number of trash cans you collected at home, max In House capacity is: 10 trash cans & when full, you have to transfer them either to Local Storage or Recycling Center! keep in mind at the first step you have to send some payloads to Recycling Center to collect some OCs (Oceanic Coins)!', null, '', () => {}, () => {});
      }}>
        <Household />
        {fullTrashCansInhouse! > 0 &&
          <div className={`${styles.badge} ${stylesApp.badge}`}
            style={{
              backgroundImage:
                `radial-gradient(circle, ${badgeLevelColor(fullTrashCansInhouse!, Settings.inApp.inHouseCapacity)} 50%, rgba(255,255,255,1) 100%)`
            }}>
            {fullTrashCansInhouse}
          </div>
        }
      </div>

    </div>
  );
}

export default App;
