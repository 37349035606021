import { Settings } from "../helpers/Settings"

export const badgeLevelColor = (currentValue:number, maxValue:number) => {
    //  console.log(`${currentValue} , ${maxValue / 3} , ${maxValue * 2 / 3}`)
    if(currentValue <= (maxValue / 3))
        return Settings.inApp.badge.low;
    else if(((maxValue / 3) < currentValue) &&
        currentValue <= (maxValue * 2 / 3))
        return Settings.inApp.badge.medium;
    else if((maxValue * 2 / 3) < currentValue)
        return Settings.inApp.badge.high;
}

export const stringifyNumber = (value: number) => {
    const result = value.toString();
    if (value < 1)
      return result.substring(0, 4);
    else if (value < 1000)
      return Math.floor(value).toString();
    else if (1000 <= value && value < 1000000)
      return Math.floor(value / 1000) + 'K';
    else if (1000000 <= value && value < 1000000000)
      return Math.floor(value / 1000000) + 'M';
    else if (1000000000 <= value && value < 1000000000000)
      return Math.floor(value / 1000000000) + 'B';
    return result;
  }