import React, { Children, forwardRef, FunctionComponent, ReactNode, useContext, useEffect, useImperativeHandle, useRef, useState } from "react";
import styles from "./operations.module.scss";
import stylesApp from '../../globalStyles.module.scss';
import { inherits } from "util";
import { Settings } from "../../helpers/Settings";
import { ArrowLeft, ArrowLeftLarge, ArrowRight, ArrowRightLarge, CloseModal, Coin, Cost, Gain, Household, HouseholdColored, LocalStockColored, LocalStorage, MiniPickup, Pallet, Pickup, RecyclingCenter, RecyclingCenterColored, Transfer, TrashCan } from "../../components/icons/icons";
import { ElementFlags } from "typescript";
import Card, { CardCommunicators } from "../../components/Card/Card";
import { shrinkFloatingPoint } from "../../handlers/correctors";
import { badgeLevelColor } from "../../handlers/ui";
import { getStack, setStack } from "../../restAPI/stackGate";
import { PopupsContext } from "../../handlers/PopupsContext";
import Modal from "../../components/Modal/Modal";
import { logJournal } from "../../restAPI/ledgerGate";
import { getBalance } from "../../restAPI/walletGate";

interface OperationsProps {
    origin: 'HOUSE' | 'LOCALSTOCK';
    //trashCans: number;
    userId: string | undefined;
    title?: string[];
    fontFamily?: string;
    fontSize?: string;

    onClick?: (value: any) => void;
    onChange?: (value: any, type: 'INHOUSE' | 'LOCALSTOCK') => void;
    onClose?: () => void
    children?: React.ReactNode;
}

export interface OperationsCommunicators {
    onUnload: () => void;
}

const Operations: FunctionComponent<OperationsProps> = (props) => {

    const { showModal, showInfoPopup, showAlertPopup, showBusy, hideBusy } = useContext(PopupsContext);

    const refDestination = useRef<HTMLDivElement>(null);
    const refTransporter = useRef<HTMLDivElement>(null);
    const refDestinations = useRef<(HTMLDivElement | null)[]>([]);
    const refTransporters = useRef<(HTMLDivElement | null)[]>([]);
    const [title, setTitle] = useState<string[]>(props.title!);
    const [cans, setCans] = useState<number>();
    const [inhouseTrashCans, setInhouseTrashCans] = useState<number>(0);
    const [inStockTrashCans, setInStockTrashCans] = useState<number>(0);
    const [occupiedPallets, setOccupiedPallets] = useState<number>(0);
    const [fullPallets, setFullPallets] = useState<number>();
    const [transferringPalletsCount, setTransferringPalletsCount] = useState<number>();
    const [palletsInRent, setPalletsInRent] = useState<number>(0);
    const [occupiedPercentage, setOccupiedPercent] = useState<number>();
    
    const [transferCost, setTransferCost] = useState<number>(props.origin === 'HOUSE' ? Settings.inApp.transfer.cost.fromHome.minPickup_localStock : Settings.inApp.transfer.cost.fromLocalStock.pickup);
    const [gain, setGain] = useState<number>(props.origin === 'HOUSE' ? 0 : Settings.inApp.transfer.gain.to_12_Pallets * Settings.inApp.transfer.maxCapacity.pickup);
    const [gainPerUnit, setGainPerUnit] = useState<number>(props.origin === 'HOUSE' ? 0 : Settings.inApp.transfer.gain.to_12_Pallets);
    const [benefit, setBenefit] = useState<number>();
    const [balance, setBalance] = useState<number>();
    
    const [visibleDestination, setVisibleDestination] = useState<HTMLDivElement>();
    const [visibleTransporter, setVisibleTransporter] = useState<HTMLDivElement>();
    const [numberOfTrips, setNumberOfTrips] = useState<number>(0);
    const [onlyFullLoad, setOnlyFullLoad] = useState<boolean>(true);

    //const [rangeGainPerPallet, setRangeGainPerPallet] = useState<number>(0);
    // let transporterObserver:IntersectionObserver;
    // let destinationObserver:IntersectionObserver;
    
    let _transferrablePallets: number|undefined = 0;
    let _transferringPallets: number|undefined = 0
    let _rangeGainPerPallet: number|undefined = 0;
    let _requiredTrips: number|undefined = 0;
    let _transporterTripCost: number|undefined = 0;

    useEffect(() => {
        (async () => {
            showBusy('loading', 'loading ...');
            await loadCriticalData();            
            hideBusy();
        })();
    }, [])

    const loadCriticalData = async () => {
        if (props.origin === 'HOUSE') {
            const rentedSpaces = await getStack(props.userId!, 'RENTEDSPACE');
            if (rentedSpaces!) {
                setPalletsInRent(rentedSpaces.canCount / (Settings.inApp.palletCapacity));
            }
            const resultInStock = await getStack(props.userId!, 'INHOUSE');
            if (resultInStock!) {
                setInhouseTrashCans(resultInStock.canCount);
            }
        }

        const resultInStock = await getStack(props.userId!, 'INSTOCK');
        if (resultInStock!) {
            setInStockTrashCans(resultInStock.canCount);
            setFullPallets(Math.floor(resultInStock.canCount / Settings.inApp.palletCapacity));
        }

        const resultBalance = await getBalance(props.userId!);
        if(resultBalance!)
            setBalance(resultBalance.worth);
    }

    useEffect(() => {
        if (inStockTrashCans!) {
            setOccupiedPercent(Math.ceil(inStockTrashCans * 100 / (palletsInRent! * Settings.inApp.palletCapacity)));
            setOccupiedPallets(Math.ceil(inStockTrashCans / Settings.inApp.palletCapacity));                        
        }
    }, [palletsInRent, inStockTrashCans, inhouseTrashCans, fullPallets])
    
    /**
     * scroll handling
     */
    useEffect(() => {
        const destinationObserver = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {     
                        switch (entry.target.getAttribute('title')) {
                            case 'LOCALSTOCK':
                                setTitle(['transfer to', 'local stock']);
                                if (props.origin === 'HOUSE') {
                                    setTransferCost(Settings.inApp.transfer.cost.fromHome.minPickup_localStock);
                                    setGain(0);
                                    setGainPerUnit(0);
                                    setNumberOfTrips(1);
                                }
                                break;
                            case 'RECYCLINGCENTER':
                                setTitle(['transfer for', 'recycling']);
                                if (props.origin === 'HOUSE') {
                                    setTransferCost(Settings.inApp.transfer.cost.fromHome.minPickup_recyclingCenter);
                                    setGain(Settings.inApp.transfer.gain.under_1_Pallet * (Settings.inApp.transfer.maxCapacity.miniPickup / Settings.inApp.palletCapacity));
                                    setGainPerUnit(Settings.inApp.transfer.gain.under_1_Pallet / Settings.inApp.palletCapacity);
                                    setNumberOfTrips(1);
                                }
                                break;
                            }
                            setVisibleDestination(entry.target as HTMLDivElement);
                    }
                });
            },
            {
                root: refDestination.current,
                threshold: 0.8 // Adjust this value based on your needs 
            });

        const transporterObserver = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        if (props.origin === 'LOCALSTOCK') {                            
                            switch (entry.target.getAttribute('title')) {
                                case 'PICKUP':
                                    //setTransferrablePalletsCount( Settings.inApp.transfer.maxCapacity.pickup);
                                    setTransferCost(Settings.inApp.transfer.cost.fromLocalStock.pickup);
                                    setGain(Settings.inApp.transfer.gain.to_12_Pallets * Settings.inApp.transfer.maxCapacity.pickup);
                                    setGainPerUnit(Settings.inApp.transfer.gain.to_12_Pallets);
                                    break;
                                case 'CAMION':
                                    //setTransferrablePalletsCount(Settings.inApp.transfer.maxCapacity.camion);
                                    setTransferCost(Settings.inApp.transfer.cost.fromLocalStock.camion);
                                    setGain(Settings.inApp.transfer.gain.to_60_Pallets * Settings.inApp.transfer.maxCapacity.camion);
                                    setGainPerUnit(Settings.inApp.transfer.gain.to_60_Pallets);
                                    break;
                                case 'TRUCK':
                                    //setTransferrablePalletsCount(Settings.inApp.transfer.maxCapacity.truck);
                                    setTransferCost(Settings.inApp.transfer.cost.fromLocalStock.truck);
                                    setGain(Settings.inApp.transfer.gain.over_60_Pallets * Settings.inApp.transfer.maxCapacity.truck);
                                    setGainPerUnit(Settings.inApp.transfer.gain.over_60_Pallets);
                                    break;
                                }
                            }
                            setVisibleTransporter(entry.target as HTMLDivElement);
                            //calculateBenefit();
                    }
                });
            },
            {
                root: refTransporter.current,
                threshold: 0.8 // Adjust this value based on your needs 
            });

        refDestinations.current.forEach(child => {
            if (child){
                destinationObserver.observe(child);               
            } 
        });

        refTransporters.current.forEach(child => {
            if (child){
                transporterObserver.observe(child);
            } 
        });

        return () => {
            refDestinations.current.forEach(child => {
                if (child) destinationObserver.unobserve(child);
            });

            refTransporters.current.forEach(child => {
                if (child) transporterObserver.unobserve(child);
            });
        }
    }, [fullPallets])

    useEffect(() => {
        if (transferCost!){
            console.log('FULL PALLETS: ', fullPallets);
            console.log('benefit REQs: ', transferCost!, gain!, gainPerUnit!);
            //setBenefit(calculateBenefit());
        }
    }, [transferCost, gain, gainPerUnit, fullPallets, numberOfTrips, visibleTransporter, visibleDestination, title])

    useEffect(() => {
        console.log('BENEFIT: ', benefit);
    }, [benefit, numberOfTrips, occupiedPallets])

    useEffect(() => {
        
            calculateBenefit();
        
    }, [visibleTransporter, visibleDestination, title])

    useEffect(() => {

    }, [benefit, gainPerUnit, numberOfTrips, transferCost])
    
    const calculateBenefit = () => {
        _transferrablePallets = fullPallets;
        _transferringPallets = vehicleTransferringPallets();// _transferrablePallets;//ToDO let the user set the number to transfer! for now it is max 
        _rangeGainPerPallet = rangeGainPerPallet();
        _requiredTrips = requiredTrips();
        _transporterTripCost = getTripCost();
        
        let benefit = 0;
        
        console.log(`BEN CALC: \t\t\t (${inhouseTrashCans}`);
        if (_rangeGainPerPallet! && _transporterTripCost! && _requiredTrips!) {
            //check if is being tranferred to Recycling Center (from either House/Local Stock)
            if (visibleDestination === refDestinations!.current![1]!) {
                if (props.origin === 'HOUSE' && inhouseTrashCans!){
                    benefit = (inhouseTrashCans * _rangeGainPerPallet) - (_transporterTripCost * _requiredTrips);
                }
                else if(_transferringPallets!) {
                    benefit = (_transferringPallets * _rangeGainPerPallet) - (_transporterTripCost * _requiredTrips);
                }
            }

            setTransferringPalletsCount(_transferringPallets);
            setGainPerUnit(_rangeGainPerPallet!);
            setNumberOfTrips(_requiredTrips!);
            setTransferCost(_transporterTripCost);
        }
        
        console.log(`benefit VAR: \t\t\t (${_transferringPallets} * ${_rangeGainPerPallet}) - (${_transporterTripCost} * ${_requiredTrips})`);
        setBenefit(benefit);

        return benefit;
    }

    const vehicleTransferringPallets = () => {
        let vehicleCap = 0;
        if (props.origin === 'HOUSE') {
            vehicleCap = Settings.inApp.transfer.maxCapacity.miniPickup;
        }
        if (props.origin === 'LOCALSTOCK') {
            if (visibleTransporter === refTransporters!.current![0])
                vehicleCap = Settings.inApp.transfer.maxCapacity.pickup;
            else if (visibleTransporter === refTransporters!.current![1])
                vehicleCap = Settings.inApp.transfer.maxCapacity.camion;
            else if (visibleTransporter === refTransporters!.current![2])
                vehicleCap = Settings.inApp.transfer.maxCapacity.truck;
        }

        return Math.floor(_transferrablePallets! / vehicleCap) * vehicleCap;
    }

    const rangeGainPerPallet = () => {
        if (props.origin === 'HOUSE' && inhouseTrashCans === 10) {
            return Settings.inApp.transfer.gain.under_1_Pallet / Settings.inApp.palletCapacity;
        }
        if (_transferrablePallets!) {
            if (props.origin === 'LOCALSTOCK') {
                if (visibleTransporter === refTransporters!.current![0])
                    return Settings.inApp.transfer.gain.to_12_Pallets;
                else if (visibleTransporter === refTransporters!.current![1])
                    return Settings.inApp.transfer.gain.to_60_Pallets;
                else if (visibleTransporter === refTransporters!.current![2])
                    return Settings.inApp.transfer.gain.over_60_Pallets;
                return 0;
            }
        }
        return undefined;
    }

    const requiredTrips = () => {
        let tripsCount = 1;
        let vehicleCap = 0;
        if (props.origin === 'HOUSE') {
            return 1;
        }
        if (_transferrablePallets!) {
            //default value from origin 'HOUSE' is 1
            if (props.origin === 'LOCALSTOCK') {
                if (visibleTransporter === refTransporters!.current![0])
                    vehicleCap = Settings.inApp.transfer.maxCapacity.pickup;
                else if (visibleTransporter === refTransporters!.current![1])
                    vehicleCap = Settings.inApp.transfer.maxCapacity.camion;
                else if (visibleTransporter === refTransporters!.current![2])
                    vehicleCap = Settings.inApp.transfer.maxCapacity.truck;

                if (onlyFullLoad)
                    tripsCount = Math.floor(_transferrablePallets / vehicleCap);
                else
                    tripsCount = Math.ceil(_transferrablePallets / vehicleCap);
            }
            console.log('calculated trips ... ', _transferrablePallets, tripsCount);
            return tripsCount;
        }
        return undefined;
    }

    const getTripCost = () => {
        if (props.origin === 'HOUSE'){
            if(visibleDestination === refDestinations!.current[0])
                return Settings.inApp.transfer.cost.fromHome.minPickup_localStock;
            else if(visibleDestination === refDestinations!.current[1])
                return Settings.inApp.transfer.cost.fromHome.minPickup_recyclingCenter;
        }
        else if (props.origin === 'LOCALSTOCK'){
            if(visibleTransporter === refTransporters!.current[0])
                return Settings.inApp.transfer.cost.fromLocalStock.pickup;
            else if(visibleTransporter === refTransporters!.current[1])
                return Settings.inApp.transfer.cost.fromLocalStock.camion;
            else if(visibleTransporter === refTransporters!.current[2])
                return Settings.inApp.transfer.cost.fromLocalStock.truck;
        }
        return 0;
    }

    return (
        <div className={styles.container}>
            <div className={stylesApp.separatorHalf}></div>
            <div className={styles.header}>
                <div className={styles.title}>
                    <div className={styles.titleTop}>
                        {title![0]}
                    </div>
                    <div className={styles.titleBottom}>
                        {title![1]}
                    </div>
                </div>
                <div className={styles.close}>
                    <CloseModal onClick={() => {
                        if (props.onClose!)
                            props.onClose();
                    }} />
                </div>
            </div>
            <div className={styles.body}>

                <div className={styles.destinationCardArea}>
                    {props.origin === 'HOUSE' &&
                        <ArrowLeft />
                    }
                    <div className={stylesApp.splitterDouble}></div>
                    <div ref={refDestination} className={styles.cardWrapper}>
                        {props.origin === 'HOUSE' &&
                            <div ref={(el) => (refDestinations.current![0] = el)} title='LOCALSTOCK' className={styles.card}>
                                <div className={styles.iconArea}>
                                    <LocalStockColored className={styles.icon}/>
                                    {(occupiedPercentage! && palletsInRent!) &&
                                        <div className={styles.pieContainer}
                                            style={{ background: `conic-gradient(${badgeLevelColor(inStockTrashCans / Settings.inApp.palletCapacity, palletsInRent!)} ${occupiedPercentage * 3.6}deg, #00ff0000 ${occupiedPercentage * 3.6}deg 360deg)` }}>
                                        </div>
                                    }
                                    {((inStockTrashCans % Settings.inApp.palletCapacity) > 0) &&
                                        <div className={styles.extraTrashCans}>
                                            <div className={styles.trashCans}>
                                                -{(Settings.inApp.palletCapacity - (inStockTrashCans % Settings.inApp.palletCapacity))}<TrashCan height={16} width={16} />
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className={styles.title}>
                                    local stock {occupiedPallets}/{palletsInRent}
                                </div>
                            </div>
                        }
                        <div ref={(el) => (refDestinations.current![1] = el)} title='RECYCLINGCENTER' className={styles.card}>
                            <div className={styles.iconArea}>
                                <RecyclingCenterColored className={styles.icon} />
                            </div>
                            <div className={styles.title}>
                                recycling center
                            </div>
                        </div>

                    </div>
                    <div className={stylesApp.splitterDouble}></div>
                    {props.origin === 'HOUSE' &&
                        <ArrowRight />
                    }
                </div>

                <div className={stylesApp.verticalConnector} style={{ height: '5%' }}></div>

                <div className={styles.transferCardArea}>
                    {props.origin === 'LOCALSTOCK' &&
                        <ArrowLeftLarge />
                    }
                    {props.origin === 'HOUSE' &&
                        <div className={stylesApp.splitter}></div>
                    }
                    <div ref={refTransporter} className={styles.cardWrapper + 
                        ` ${
                            ((benefit! > 1 && props.origin === 'LOCALSTOCK') || 
                            (props.origin === 'HOUSE' && inhouseTrashCans === 10 && visibleDestination === refDestinations!.current![1]) ||
                            (props.origin === 'HOUSE' && inhouseTrashCans === 10 && visibleDestination === refDestinations!.current![0] && balance! >= Settings.inApp.transfer.cost.fromHome.minPickup_localStock))
                            ? '' : styles.disabled
                        }`}>
                        {props.origin === 'HOUSE' &&
                            <div title='MINIPICKUP' ref={(el) => (refTransporters.current![0] = el)}>
                                <Card type='MINIPICKUP'
                                    cost={transferCost} gainPerUnit={gain}
                                    description={
                                        visibleDestination === refDestinations.current![1]! ?
                                            'full load gets 20 coins' :
                                            'storage cost: extra 3 coin / day'
                                    }
                                    descriptionInfo={
                                        'every 7 pallets space will cost 3 OCs daily! it will be automatically charged from your wallet!'
                                    }
                                    payloadType='TRASHCAN' />
                            </div>
                        }
                        {props.origin === 'LOCALSTOCK' &&
                            <div title='PICKUP' ref={(el) => (refTransporters.current![0] = el)}>
                                <Card type='PICKUP'
                                    cost={transferCost} gainPerUnit={gain}
                                    description={'1-12 pallets payloads gain 20 OC / pallet'}
                                    payloadType='PALLET' />
                            </div>
                        }
                        {props.origin === 'LOCALSTOCK' &&
                            <div title='CAMION' ref={(el) => (refTransporters.current![1] = el)}>
                                <Card type='CAMION'
                                    cost={transferCost} gainPerUnit={gain}
                                    description={'13-59 pallets payloads gain 50 OC / pallet'}
                                    payloadType='PALLET' />
                            </div>
                        }
                        {props.origin === 'LOCALSTOCK' &&
                            <div title='TRUCK' ref={(el) => (refTransporters.current![2] = el)}>
                                <Card type='TRUCK'
                                    cost={transferCost} gainPerUnit={gain}
                                    description={'60 and more pallets payloads gain 100 OC / pallet'}
                                    payloadType='PALLET' />
                            </div>
                        }
                    </div>
                    {props.origin === 'HOUSE' &&
                        <div className={stylesApp.splitter}></div>
                    }
                    {props.origin === 'LOCALSTOCK' &&
                        <ArrowRightLarge />
                    }
                </div>

                <div className={stylesApp.verticalConnector} style={{ height: '5%' }}></div>

                <div className={styles.source}>
                    {props.origin === 'HOUSE' &&
                        <HouseholdColored height={96} width={96} />
                    }
                    {props.origin === 'LOCALSTOCK' &&
                        <LocalStockColored height={96} width={96} />
                    }
                    {(inhouseTrashCans > 0 && props.origin === 'HOUSE') &&
                        <div className={`${styles.badge} ${stylesApp.badge}`}
                            style={{
                                backgroundImage:
                                    `radial-gradient(circle, ${badgeLevelColor(inhouseTrashCans, Settings.inApp.inHouseCapacity)} 50%, rgba(255,255,255,1) 100%)`
                            }}>
                            {inhouseTrashCans}
                        </div>
                    }

                    {(fullPallets! > 0 && props.origin === 'LOCALSTOCK') &&
                        <div className={`${styles.badge} ${stylesApp.badge}`}
                            style={{
                                backgroundImage:
                                    `radial-gradient(circle, ${badgeLevelColor(inStockTrashCans / Settings.inApp.palletCapacity, palletsInRent!)} 50%, rgba(255,255,255,1) 100%)`
                            }}>
                            {fullPallets}
                        </div>
                    }
                </div>
            </div>
            <div className={styles.action}>
                <div className={styles.summary}>
                    <div className={styles.title}>
                        {title[1]} transfer
                    </div>
                    <div className={styles.details}>
                        <div className={styles.detailRow}>
                            <div className={styles.label}>
                                {props.origin === 'HOUSE' ? 'trash cans' : 'pallets'}:
                            </div>
                            <div className={styles.value}>
                                x{props.origin === 'HOUSE' ? inhouseTrashCans! : transferringPalletsCount! }
                            </div>
                        </div>
                        <div className={styles.detailRow}>
                            <div className={styles.label}>
                                gain / {props.origin === 'HOUSE' ? 'trash cans' : 'pallets'}:
                            </div>
                            <div className={styles.value}>
                                {gainPerUnit} OC
                            </div>
                        </div>
                        <div className={styles.detailRow}>
                            <div className={styles.label}>
                                transfer cost:
                            </div>
                            <div className={styles.value}>
                                {numberOfTrips} x {transferCost} OC
                            </div>
                        </div>
                        <div className={stylesApp.separatorThin}></div>
                        <div className={styles.totalSeparator}></div>
                        <div className={stylesApp.separatorThin}></div>
                        <div className={styles.total}>
                            <div className={styles.label}>
                                benefit:
                            </div>
                            <div className={styles.value}>
                                <div className={styles.text}>
                                    {benefit
                                    //shrinkFloatingPoint(calculateBenefit())
                                    }
                                </div>
                                <div className={stylesApp.splitterThin}></div>
                                <Coin width={'24px'} height={'24px'} />
                            </div>
                        </div>

                    </div>
                </div>
                <div className={stylesApp.splitter}>
                </div>
                <div className={styles.actionButton} onClick={async () => {
                    if (props.userId!) {
                        if (props.origin! === 'HOUSE') {
                            if (inhouseTrashCans === 10) {

                                if ((visibleDestination === refDestinations!.current![0] && balance! >= Settings.inApp.transfer.cost.fromHome.minPickup_localStock) ||
                                    visibleDestination === refDestinations!.current![1]) {

                                    const result = await setStack(props.userId, inhouseTrashCans, (visibleDestination === refDestinations!.current![0] ? 'INSTOCK' : 'RECYCLE'), 'INHOUSE');
                                    //console.log(result);
                                    if (result!) {
                                        //console.log(result.result)
                                        if (result.result === 4000)
                                            showAlertPopup('', 'error', 5000, <div>you do not have enough balance to <b>rent</b> space in <b>Local Stock</b>, to make money, try <b>transferring</b> to Recycling Centers!</div>);
                                        else {
                                            if (visibleDestination === refDestinations!.current![1]) {
                                                const gain = benefit!;//calculateBenefit();
                                                const journalLog = await logJournal({
                                                    userId: props.userId!,
                                                    canCount: inhouseTrashCans,
                                                    amount: gain,
                                                    action: 'RECYCLING-GAIN',
                                                    description: `${inhouseTrashCans} direct transfer from user's home for Recycling`
                                                });
                                                if (journalLog!.result === 'created') {
                                                    console.log('JOURNAL: ', journalLog!.currentJournal!);
                                                }
                                            }

                                            showAlertPopup(
                                                '', 'message', 5000, 
                                                <div>your <b>trash cans</b> tranferred to 
                                                {visibleDestination === refDestinations!.current![0] ? <b> Local Stock </b> : <b> Recycling Center </b>}
                                                 successfully!</div>);
                                            setInhouseTrashCans(0);
                                            loadCriticalData();
                                            props.onChange!(10, 'INHOUSE');
                                        }
                                    }
                                    else
                                        showAlertPopup(result.error, 'error', 5000);
                                }
                                else{
                                    showModal('learn', 'you need 15 OC for Mini Pickup to Local Stock costs, try sending some payloads to Recycling Center to gain some OCs!', <div></div>, '', () => {}, () => {});    
                                }
                            }
                            else if (inhouseTrashCans < 10) {
                                showModal('learn', 'you have to collect 10 trash cans to let the pickup pick them for either local stock or recycling center!', <div></div>, '', () => {}, () => {});
                            }
                        }
                        else if (props.origin === 'LOCALSTOCK') {
                            if (benefit! >= 1) {
                                const result = await setStack(props.userId, fullPallets! * Settings.inApp.palletCapacity, 'RECYCLE', 'INSTOCK');
                                if (result!) {
                                    const gain = benefit!;//calculateBenefit();
                                    if(visibleTransporter === refTransporters!.current![0]){
                                        const journalLog = logJournal({
                                            userId: props.userId!,
                                            canCount: transferringPalletsCount! * Settings.inApp.palletCapacity,
                                            amount: gain,
                                            action: 'RECYCLING-GAIN', 
                                            description: `${inhouseTrashCans} directly transferred for Recycling`
                                        });
                                    }
                                    else if(visibleTransporter === refTransporters!.current![1]){
                                        const journalLog = logJournal({
                                            userId: props.userId!,
                                            canCount: transferringPalletsCount! * Settings.inApp.palletCapacity,
                                            amount: gain,
                                            action: 'RECYCLING-GAIN', 
                                            description: `${inhouseTrashCans} directly transferred for Recycling`
                                        });
                                    }
                                    else if(visibleTransporter === refTransporters!.current![2]){
                                        const journalLog = logJournal({
                                            userId: props.userId!,
                                            canCount: transferringPalletsCount! * Settings.inApp.palletCapacity,
                                            amount: gain,
                                            action: 'RECYCLING-GAIN', 
                                            description: `${inhouseTrashCans} directly transferred for Recycling`
                                        });
                                    }
                                    loadCriticalData();
                                    props.onChange!(fullPallets! * Settings.inApp.palletCapacity, 'LOCALSTOCK');
                                }
                            }
                            else {
                                showAlertPopup('recycling transfers with no gains, does not worth it!', 'info', 5000);
                            }
                        }

                    }
                }}>
                    <Transfer className={styles.actionImage + ` ${
                        ((benefit! > 1 && props.origin === 'LOCALSTOCK') || 
                        (props.origin === 'HOUSE' && inhouseTrashCans === 10 && visibleDestination === refDestinations!.current![1]) ||
                        (props.origin === 'HOUSE' && inhouseTrashCans === 10 && visibleDestination === refDestinations!.current![0] && balance! >= Settings.inApp.transfer.cost.fromHome.minPickup_localStock))
                        ? '' : styles.disabled}`}/>
                </div>
            </div>
            <div className={stylesApp.separatorHalf}></div>
        </div>
        // <div className={`${containerStyle} ${animation}`}
        //     style={{
        //         fontFamily: props.fontFamily!, fontSize: props.fontSize!,
        //         width: props.width!,
        //         height: props.height!,
        //         backgroundColor: props.glassEffect ? 'unset' : (!props.disabled ? props.color! : props.disabledColor!),
        //         borderColor: !props.disabled ? props.borderColor! : props.disabledColor!,
        //         opacity: props.opacity,
        //         zIndex: props.zIndex ? props.zIndex! : '10000',
        //     }}
        //     onClick={() => {
        //         if (!props.disabled && props.onClick)
        //             props.onClick(false);
        //     }}>
        //     {props.children}
        // </div>
    );
}

export default Operations;