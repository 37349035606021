import { FunctionComponent } from "react";
import { rejects } from "assert";
import { Settings } from "../helpers/Settings";
//import { getUserPickupCount } from "./pickup";

export interface balanceData {
    userId: string;
}

/**
 * gets wallet balance
 * gets user's wallet balance in Oceanic Coins
 * @param string userId User's Id in apps backend
 * @returns json balance (+/- number)
 */
export const getBalance = async (userId: string): Promise<any | undefined> => {
    //console.log('getBalance: ' + userId);
    return new Promise((resolve, reject) => {
        fetch(Settings.api.path + "/wallet/balance", {
            method: 'POST',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'AuthenticationToken': Settings.api.authenticationToken,
            }),
            body: JSON.stringify(
                userId
            )
        }).then((response) => {
            if (response.status === 500)
                resolve(undefined);
            if (response.status === 204)
                resolve(undefined);
            response.json().then((result) => {
                resolve(result);
            }).catch((error) => { resolve(undefined) });
        }).catch((error) => {
            resolve(undefined);
        });
    });
}