import { FunctionComponent } from "react";
import { rejects } from "assert";
import { Settings } from "../helpers/Settings";
//import { getUserPickupCount } from "./pickup";

export interface stackData {
    userId: string;
    canCount: number;
    type: 'INHOUSE' | 'INSTOCK' | 'ABORTED' | 'RECYCLE' | 'RENTEDSPACE' | 'EVERYTHING';
    createdAt?: string;
    updatedAt?: string;
}

/**
 * gets stack
 * gets user's last stacks' status of any given type 'INHOUSE' | 'INSTOCK' | 'ABORTED' | 'RECYCLE' | 'EVERYTHING'
 * @param string userId Users Id in apps backend
 * @param 'INHOUSE' | 'INSTOCK' | 'ABORTED' | 'RECYCLE' type of the stack which is being queried
 * @returns json stackData{
            userId,
            canCount,
            status,
            createdAt,
            updatedAt}
 */
export const getStack = async (userId: string, type: 'INHOUSE' | 'INSTOCK' | 'ABORTED' | 'RECYCLE' | 'EVERYTHING' | 'RENTEDSPACE'): Promise<any | undefined> => {
    return new Promise((resolve, reject) => {
        fetch(Settings.api.path + "/stack/current", {
            method: 'POST',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'AuthenticationToken': Settings.api.authenticationToken,
            }),
            body: JSON.stringify({
                userId,
                type
            })
        }).then((response) => {
            if (response.status === 500)
                resolve(undefined);
            if (response.status === 204)
                resolve(undefined);
            else {
                response.json().then((result) => {
                    resolve(result);
                }).catch((error) => { resolve(undefined) });
            }
        }).catch((error) => {
            resolve(undefined);
        });
    });
}

/**
 * records new stack 
 * records new Trash Cans adding to target type 'INHOUSE': just adds to INHOUSE, 'INSTOCK': reduces from INHOUSE adding to INSTOCK, 'RECYCLE': reduces from INSTOCK adding to 'RECYCLE'
 * @param string userId 
 * @param number canCount number recyclables being updated by Trash Cans 
 * @param type either of 'INHOUSE' | 'INSTOCK' | 'RECYCLE' where stack is headed to
 * @param origin either of 'COLLECTING' | 'INHOUSE' | 'INSTOCK' where stack is shipped from (used to calculate and decrease number of existing stacks)
 * @returns json {userId, newCount [of the target]}
 */
export const setStack = async (userId: string, canCount: number, type: 'INHOUSE' | 'INSTOCK' | 'RECYCLE', origin: 'ONSHORE' | 'INHOUSE' | 'INSTOCK'): Promise<any | undefined> => {
    return new Promise((resolve, reject) => {
        fetch(Settings.api.path + "/stack/arrival", {
            method: 'POST',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'AuthenticationToken': Settings.api.authenticationToken,
            }),
            body: JSON.stringify({
                userId,
                canCount,
                type,
                origin
            })
        }).then((response) => {
            if (response.status === 500)
                resolve(undefined);
            if (response.status === 204)
                resolve(undefined);
            else {
                response.json().then((result) => {
                    console.log(result.result, result.currentStack);
                    resolve(result);
                }).catch((error) => { resolve(undefined) });
            }
        }).catch((error) => {
            resolve(undefined);
        });
    });
}
