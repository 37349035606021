import {createContext, useState} from "react";
import { UserData } from "../restAPI/userGate";

interface GlobalContextProps {
    //user authentication
    user: string|undefined,
    setUser:(userAccount:string) => void,

    notifications: any|undefined,
}

export const GlobalContext = createContext<GlobalContextProps>({
    //user authentication
    user: undefined,
    setUser: (value:string)=>{},

    notifications: undefined,
});