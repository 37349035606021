export const Settings = {
  api: {
    path: process.env.NODE_ENV === 'production' ? 'https://plato.sustainablife.online' : 'http://emerald:8080',
    authenticationToken: 'plato!inOcean24'
  },

  tempCategories: [
    { 'label': 'plastics (mix.)', 'value': '0' },
  ],

  inApp: {
    palletCapacity: 15, //max number of trash can (content) on each pallet
    trashCanCapacity: 80, //max number of 1.5 ltr PET bottles fitting in each trash can
    inHouseCapacity: 10, //max number of inHouse trash cans 
    spacePalletNumber: 7, //number of pallets in each rented space 

    modalDialogLoadTime: 500,
    modalDialogUnloadTime: 500,

    transfer: {
      maxCapacity: {
        miniPickup: 10,
        pickup: 3, 
        camion: 15,
        truck: 65
      },

      cost: {
        fromHome: {
          minPickup_localStock: 15,
          minPickup_recyclingCenter: 17
        },

        fromLocalStock: {
          pickup: 50,
          camion: 130,
          truck: 500
        }
      },

      gain:{
        under_1_Pallet: 30, //30 coins for full pallet (15 Trash Cans capacity)
        to_12_Pallets: 20,//20 coins for each pallet
        to_60_Pallets: 50,//50 coins for each pallet
        over_60_Pallets: 100//100 coins for each pallet
      }
    },

    badge: {
      low: '#49cc5c',
      medium: '#fb6640',
      high: '#f82553'
    }
  }

}