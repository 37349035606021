import React, { FunctionComponent, useContext, useEffect, useState } from "react";
import styles from "./journal.module.scss";
import stylesApp from '../../globalStyles.module.scss';
import { CloseModal, Cost, Gain, RecordsFirst, RecordsLast, RecordsNext, RecordsPrevious } from "../../components/icons/icons";
import { getJournal } from "../../restAPI/ledgerGate";
import { PopupsContext } from "../../handlers/PopupsContext";

interface JournalProps {
    userId: string;
    title?: string[];
    fontFamily?: string;
    fontSize?: string;
    onClick?: (value: any) => void;
    onChange?: (value: any) => void;
    onClose?: () => void
    children?: React.ReactNode;
}

export interface JournalCommunicators {
    onUnload: () => void;
}

const Journal: FunctionComponent<JournalProps> = (props) => {

    const { showModal, showInfoPopup, showAlertPopup, showBusy, hideBusy } = useContext(PopupsContext);

    const [title, setTitle] = useState<string[]>(props.title!);
    const [recordCount, setRecordCount] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [journal, setJournal] = useState<any>();

    useEffect(() => {
        (async () => {
            const result = await getJournal(props.userId, currentPage, 10);
            if (result!) {
                //console.log(result);
                setRecordCount(result.count);
                setJournal(result.rows);
                // setJournal(result.rows.sort((firstItem: any, nextItem: any) => {
                //     return firstItem.createdAt <= nextItem.createdAt ? 1 : -1;
                // }));
                // console.log(journal);
                // result.rows.map((element: any) => {

                // });
            }
        })();
    }, [currentPage])

    const setDirection = (journal: string, size:number) => {
        return journal!.endsWith('GAIN') ? <Gain  width={size} height={size} /> : <Cost width={size} height={size} />;
    }

    return (
        <div className={styles.container}>
            <div className={stylesApp.separator}></div>
            <div className={styles.header}>
                <div className={styles.title}>
                    <div className={styles.titleTop}>
                        {title![0]}
                    </div>
                    <div className={styles.titleBottom}>
                        {title![1]}
                    </div>
                </div>
                <div className={styles.close}>
                    <CloseModal onClick={() => {
                        if (props.onClose!)
                            props.onClose();
                    }} />
                </div>
            </div>
            <div className={stylesApp.separatorDouble}></div>
            <div className={styles.body}>
                {journal! &&
                    <div className={styles.journalContainer}>
                        <div className={styles.journalHeader}>
                            <div className={styles.cell} style={{width: '40px'}}>
                                +/-
                            </div>
                            <div className={styles.cell} style={{width: '55px'}}>
                                count
                            </div>
                            <div className={styles.cell} style={{width: '40px'}}>
                                val
                            </div>
                            <div className={styles.cell} style={{width: '60px'}}>
                                date
                            </div>
                            <div className={styles.descriptionCell}>
                                description
                            </div>
                        </div>
                        {journal.map((element: any, index: number) => {
                            return (
                                <div className={styles.journalRow} key={index} onClick={() => {
                                    showInfoPopup('jornal details', '',
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                            <div className={stylesApp.separator}></div>
                                            <div style={{ display: 'flex', flexDirection: 'row', width: '90%', justifyContent: 'center' }}>
                                                <div className={styles.cell} style={{width: '40px'}}>
                                                {setDirection(element.action, 32)}
                                                </div>
                                                <div className={stylesApp.splitterDouble}></div>
                                                <div style={{ display: 'flex', flexDirection: 'column', margin: '30' }}>
                                                    <div>
                                                        can count of {element.canCount}
                                                    </div>
                                                    <div>
                                                        {element.description}
                                                    </div>
                                                    <div>
                                                        {element.action.endsWith('GAIN') ? 'made ' : 'cost '}{element.amount} OC
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'row', width: '90%', justifyContent: 'flex-end' }}>
                                                {element.createdAt.split('T')[0].replaceAll('-', '/') + ' ' + element.createdAt.split('T')[1].substring(0, 5)}
                                            </div>
                                        </div>
                                    );
                                }}>
                                    <div className={styles.cell} style={{width: '40px'}}>
                                        {setDirection(element.action, 24)}
                                    </div>
                                    <div className={styles.cell} style={{width: '55px'}}>
                                        {element.canCount > 100 ? Math.floor(element.canCount/100) : element.canCount}
                                    </div>
                                    <div className={styles.cell} style={{width: '40px'}}>
                                        {element.amount > 100 ? Math.floor(element.amount/100) : element.amount}
                                    </div>
                                    <div className={styles.dateCell} style={{width: '60px'}}>
                                        <div>
                                            {element.createdAt.split('T')[0].replaceAll('-', '/')}
                                        </div>
                                        <div>
                                            {element.createdAt.split('T')[1].substring(0, 5)}
                                        </div>
                                    </div>
                                    <div className={styles.descriptionCell}>
                                        {element.description}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                }
                {!journal &&
                    <div className={styles.empty}>
                        no record of achievements yet!
                    </div>
                }
            </div>
            {journal! &&
                <div className={styles.footerRow}>
                    <div className={styles.controls}>
                        <div className={stylesApp.splitter}></div>
                        <RecordsFirst onClick={() => {
                            setCurrentPage(0);
                        }}/>
                        <div className={stylesApp.splitter}></div>
                        <RecordsPrevious onClick={() => {
                            setCurrentPage(currentPage > 0 ? (currentPage - 1) : currentPage);
                        }}/>
                    </div>
                    {(currentPage + 1) + ' of ' + Math.ceil(recordCount / 10)}
                    <div className={styles.controls}>
                        <RecordsNext onClick={() => {
                            setCurrentPage(currentPage < (Math.ceil(recordCount / 10) - 1) ? (currentPage + 1) : currentPage);
                        }}/>
                        <div className={stylesApp.splitter}></div>
                        <RecordsLast onClick={() => {
                            setCurrentPage(Math.ceil(recordCount / 10) - 1);
                        }}/>
                        <div className={stylesApp.splitter}></div>
                    </div>
                </div>
            }
            <div className={stylesApp.separatorDouble}></div>
        </div>
    );
}

export default Journal;