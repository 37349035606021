import { FunctionComponent } from "react";
import { rejects } from "assert";
import { Settings } from "../helpers/Settings";
//import { getUserPickupCount } from "./pickup";

export interface journalData {
    userId: string;
    canCount: number;
    /**
     * all numbers are positive
     * Incoming values are defined in action column ending with -GAIN
     * Outgoing values are defined in action column ending with -COST
     */
    amount: number;
    /**
     * LOCALSTOCK-COST : defines the recorded journal indicates to [renting a 7-pallets space in Local Stock]
     * MINIPICKUP-COST : defines the recorded journal indicates to [taking a Mini Pickup]
     * PICKUP-COST : defines the recorded journal indicates to [taking a Pickup]
     * CAMION-COST : defines the recorded journal indicates to [taking a Camion]
     * TRUCK-COST : defines the recorded journal indicates to [taking a Truck]
     * WALLETWITHDRAWAL-COST : defines the recorded journal indicates to [user cash withdrawal from account]
     * RECYCLING-GAIN : defines the recorded journal indicates to [Recycling Center's payment for getting user's payload of recyclables]
     * WALLETCHARGING-GAIN : defines the recorded journal indicates to [user, charging their account via cash]
     */
    action:
    'LOCALSTOCK-COST'
    | 'MINIPICKUP-COST'
    | 'PICKUP-COST'
    | 'CAMION-COST'
    | 'TRUCK-COST'
    | 'WALLETWITHDRAWAL-COST'
    | 'EVACUATION-COST'
    | 'RECYCLING-GAIN'
    | 'WALLETCHARGING-GAIN';
    /**
     * X Trash Can Transferred from user's House to Local Stock via Mini Pickup
     * X Trash Can Transferred from user's House to Recycling Center via Mini Pickup
     * a X pallet space rental paid for one day
     * X pallets Transferred to Recycling Center via Pickup
     * X pallets Transferred to Recycling Center via Camion
     * X pallets Transferred to Recycling Center via Truck
     */
    description: string;
    createdAt?: string;
    updatedAt?: string;
}

/**
 * gets stack
 * gets user's last stacks' status of any given type 'INHOUSE' | 'INSTOCK' | 'ABORTED' | 'RECYCLE'
 * @param string userId Users Id in apps backend
 * @param 'INHOUSE' | 'INSTOCK' | 'ABORTED' | 'RECYCLE' type type of the stack which is being queried
 * @returns json stackData{
            userId,
            canCount,
            status,
            createdAt,
            updatedAt}
 */
export const logJournal = async (journalToLog: journalData): Promise<any | undefined> => {
    return new Promise((resolve, reject) => {
        fetch(Settings.api.path + "/journal/new", {
            method: 'POST',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'AuthenticationToken': Settings.api.authenticationToken,
            }),
            body: JSON.stringify(
                journalToLog
            )
        }).then((response) => {
            if (response.status === 500)
                resolve(undefined);
            if (response.status === 204)
                resolve(undefined);
            response.json().then((result) => {
                resolve(result);
            }).catch((error) => { resolve(undefined) });
        }).catch((error) => {
            resolve(undefined);
        });
    });
}

/**
 * history of user actions
 * returns a history of user action as a json array
 * @param string userId 
 * @param number page
 * @returns json[] [{actionData}]
 */
export const getJournal = async (userId: string, page: number, pageSize: number): Promise<any | undefined> => {
    return new Promise((resolve, reject) => {
        fetch(Settings.api.path + "/journal/history", {
            method: 'POST',
            mode: 'cors',
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'AuthenticationToken': Settings.api.authenticationToken,
            }),
            body: JSON.stringify({
                userId, 
                page, 
                pageSize
            })
        }).then((response) => {
            if (response.status === 500)
                resolve(undefined);
            if (response.status === 204)
                resolve(undefined);
            response.json().then((result) => {
                resolve(result);
            }).catch((error) => { resolve(undefined) });
        }).catch((error) => {
            resolve(undefined);
        });
    });
}
